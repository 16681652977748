/* PLESE DO NOT SET DEFAULT STYLES HERE, let's user aphrodite where it's needed */
html {
	box-sizing: border-box;
	font-family: Montserrat, "Helvetica Neue", Arial, sans-serif;
	font-size: 14px;
	line-height: 1.5;
	-ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}
*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
    margin: 0;
    overflow-x: auto;
}

[hidden] {
	display: none;
}

button, input, textarea {
	font-family: Montserrat, "Helvetica Neue", Arial, sans-serif;
}

#root {
	width: 		100%;
	position: 	relative;
}
/* PLESE DO NOT SET DEFAULT STYLES HERE, let's user aphrodite where it's needed */

.fade-enter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0.01;
}
.fade-enter.fade-enter-active {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 1;
    will-change: opacity;
    transition: opacity 150ms ease-in;
}
.fade-exit {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 1;
}
.fade-exit.fade-exit-active {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0.01;
    will-change: opacity;
    transition: opacity 150ms ease-in;
}